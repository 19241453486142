.courses {
  &-list {
    display: flex;
    flex-direction: row;
    min-height: 410px;
    min-width: 100%;
    &__item {
      display: flex;
      flex-direction: column;
      .course-card {
        height: 100%;
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        cursor: pointer;
        transition: background 0.2s ease-in;
        &:hover {
          background: rgba(59,130,246,.5);
        }
        &__status-list {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-bottom: 35px;
          .badge {
            &.bg-dark {
              margin-right: 5px;
            }
          }
        }
        &__header {
          display: flex;
          align-items: center;
          &__cover {
            margin-right: 13px;
            width: 80px;
            height: 80px;
            border-radius: 50%;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
          }
          &__title {
            font-family: 'Rothek', sans-serif;
            font-size: 24px;
            line-height: 27.6px;
            overflow: hidden;
          }
        }
        &__description {
          margin-top: 24px;
          font-size: 16px;
          line-height: 21.6px;
        }
      }
    }
    @media screen and (max-width: 768px) {
      &:nth-child(4n+1) {
        .course-card {
          background: rgba(59,130,246,.5);
        }
      }
      &:nth-child(4n+2) {
        .course-card {
          background: rgba(59,130,246,.5);
        }
      }
      &:nth-child(4n+3) {
        .course-card {
          background: #F7941E;
        }
      }
      &:nth-child(4n+4) {
        .course-card {
          background: #6EB253;
        }
      }
    }
  }
}