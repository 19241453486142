
.footer {
  background: #ededed;
  color: #A5A5A5;
  padding: 39px 24px;
  margin: 0;
  .menu {
    display: flex!important;
    @media screen and (max-width: 768px) {
      &__item a {
        color: #1F1F1F;
      }
    }
  }
  &__menu {
    display: flex;
    padding: 52px 117px 0;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        color: #A5A5A5;
        margin-bottom: 24px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          text-decoration: none;
          color: #A5A5A5;
          &:hover {
            color: #1f1f1f;
          }
        }
      }
    }
    &__big, &__small {
      border-top: 2px solid #EBEEF5;
    }
    &__small {
      font-weight: 400;
      font-size: 12px;
      line-height: 135%;
    }
  }
  @media screen and (max-width: 768px) {
    padding: 39px 0;
  }
  .text-footer {
    @media screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 135%;
    }
  }
}
