.feedback {
  display: flex;
  letter-spacing: -0.01em;
  color: #3E3E3E;
  &__text {
    flex-grow: 1;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 135%;
  }
  &__form {
    flex-grow: 2;
    max-width: 100%;
    width: 464px;
    form {
      max-width: 100%;
      width: 464px;
      font-weight: 400;
      font-size: 12px;
      line-height: 135%;
      input {
        height: 42px;
        border-radius: 10px;
      }
      .mr-24 {
        margin-right: 24px;
      }
      a {
        color: inherit;
      }
    }
    &__footer {
      justify-content: space-between;
      align-items: center;
      &__text {
        width: 210px;
        font-weight: 400;
        font-size: 12px;
        line-height: 135%;
        @media screen and (max-width: 768px) {
          margin: 20px 0  20px;
        }
      }
      a {
        text-decoration: underline;
      }
      button {
        height: auto;
        @media screen and (max-width: 768px) {
          width: 100%;
          display: block;
        }
      }
      @media screen and (max-width: 768px) {
        justify-content: center;
        flex-direction: column;
      }
    }
  }
}