
.test-timer {
  display: flex;
  align-items: center;
  &__clock {
    font-size: 36px;
    line-height: 1;
    font-weight: 700;
  }
}
