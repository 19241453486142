.btn {
  padding: 16px 24px;
  border-radius: 32px;
  color: #1F1F1F;
  font-size: 16px;
  line-height: 1;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  background: #fff;
  transition: all 0.2s ease-in;
  cursor: pointer;
  border: none;
  text-wrap: nowrap;
  &-border {
    border: 2px solid #1F1F1F!important;
  }
  svg {
    margin-right: 1rem;
  }
  &.skeleton {
    width: 130px;
    height: 48px;
    border: 2px solid rgba(0,0,0,0)!important;
    color: rgba(0,0,0,0);
    &:nth-child(4n+1) {
      width: 140px;
    }
    &:nth-child(4n+2) {
      width: 160px;
    }
    &:nth-child(4n+3) {
      width: 150px;
    }
    &:nth-child(4n+4) {
      width: 110px;
    }
    &:nth-child(4n+5) {
      width: 85px;
    }
  }
  &-outline {
    color: #A5A5A5;
    &:hover {
      background: none!important;
      color: #1f1f1f;
    }
  }
  &:hover, &.active {
    background: rgba(59,130,246,.5);
  }
  &-lg {
    font-size: 20px;
    padding: 28px 56px;
    border: 4px solid #1F1F1F;
    border-radius: 42px;
    text-transform: uppercase;
  }
  img {
    margin-right: 16px;
  }
  &-without-border {
    border: none;
    &:hover {
      background: inherit;
      color: #1f1f1f;
    }
  }
  &-sm {
    color: #1F1F1F;
    border-radius: 42px;
    padding: 20px 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
    &:hover, &.active {
      background: #3E3E3E;
    }
  }
  &-black {
    color: #fff;
    background: #1F1F1F;
    border-radius: 42px;
    padding: 20px 48px;
    font-weight: 500;
    font-size: 14px;
    line-height: 1;
    &:hover, &.active {
      background: #3E3E3E;
    }
  }
  &-danger {
    background: #E55151;
    &:hover, &.active {
      background: #3E3E3E;
    }
  }
  &-purple {
    background: #C098F9;
    &:hover, &.active {
      background: #3E3E3E;
    }
  }
  &-up {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      margin: 0 10px -2px 0;
    }
    @media screen and (max-width: 768px) {
      padding: 10px 20px;
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
    }
  }
  @media screen and (max-width: 768px) {
    &-lg {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 135%;
      padding: 16px 24px;
      display: inline-block;
      background: rgba(59,130,246,.5);
      border: none;
      color: #1F1F1F!important;
    }
  }
}