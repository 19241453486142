.about-page {
  &__wrapper{
    &-icons {
      display: none;
    }
    padding: 60px 315px 0 141px;
    &-partners {
      padding: 60px 141px 0 141px;
      @media screen and (max-width: 768px) {
        .circle-number {
          margin: 0 auto;
        }
      }
    }
    @media screen and (max-width: 768px) {
      padding: 123px 0 0;
      &-icons {
        display: block;
      }
    }
    .circle-number {
      width: 80px;
      height: 80px;
      color: #fff;
      border-radius: 50%;
      font-family: 'Rothek', sans-serif;
      font-size: 48px;
      line-height: 57px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .cube {
      width: 24px;
      height: 24px;
      border-radius: 6px;
      margin-bottom: 10px;
    }
  }
  .kinesis {
    position: absolute;
    @media screen and (max-width: 768px) {
      display: none;
    }
    &-first {
      top: 130px;
      left: 0;
    }
    &-second {
      right: 0!important;
      top: 189px;
    }
    &-third {
      right: -300px!important;
      top: 634px;
    }
    &-fourth {
      right: -300px!important;
      top: 1484px;
    }
    &-five {
      left: 57px!important;
      top: 1310px;
    }
  }

  .container {
    position: relative;
    .circle {
      position: absolute;
      border-radius: 100%;
    }

    .circle.circle-purple {
      border: 10px solid red;
      opacity: 0.1;
      width: 4vw;
      height: 4vw;
      left: 100px;
      top: 250px;
    }

    .circle.circle-purple1 {
      border: 15px solid red;
      opacity: 0.1;
      width: 8vw;
      height: 8vw;
      right: -20px;
      bottom: 170px;
    }
    .circle.circle-purple2 {
      background-color: red;
      opacity: 0.1;
      width: 5vw;
      height: 5vw;
      left: 20%;
      bottom: 17%;
    }

    .circle.circle-purple3 {
      border: 15px solid red;
      opacity: 0.3;
      width: 3vw;
      height: 3vw;
      top: 80%;
      left: 60%;
    }
  }
}