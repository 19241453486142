.module-list {
  border: none;
  .el-collapse {
    border: none;
    &-item__header {
      height: auto!important;
    }
  }
  .el-icon-arrow-right:before {
    font-size: 24px;
    font-weight: 700;
  }
  &__title {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 125%; /* 30px */
    letter-spacing: -0.24px;
    padding: 24px 32px;
    width: 100%;
  }
}