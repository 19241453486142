.video-lectures {
  &__module-title {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
  &__video {
    &-title {
      color: #828282;
      font-size: 1.8rem;
      line-height: 2.4rem;
      margin: 1.2rem 0 3.2rem;
    }
    &-description {
      p {
        margin: 0;
      }
      .section__lk-content-item-data-title {
        font-size: 1.8rem;
        margin-left: 2.4rem;
      }
      &__list {
        li {
          font-size: 18px;
          line-height: 32px;
          &:before {
            background: #1C75BC!important;
          }
          &:hover, &:hover a {
            &:before {
              background: #dc3545!important;
            }
            color: #dc3545;
          }
          .tooltip-video {
            width: 256px;
            height: 144px;
          }
        }
      }
    }
  }
  &__player {
    &-box {
      .video-js {
        border-radius: 24px;
        background: none;
        &.vjs-paused {
          .vjs-big-play-button {
            position: absolute;
            top: 50%;
            left: 50%;
            border: none;
            width: 8rem;
            height: 8rem;
            background-size: 8rem auto;
            display: block;
            content: "";
            z-index: 1;
            cursor: pointer;
            transform: translate(-50%, -50%);
            transition: .3s ease-in-out;
            border-radius: 50%;
            background: url(/img-front/arrow-blue-target.svg) 50% no-repeat;
            &:hover {
              width: 10rem;
              height: 10rem;
              background-size: 2rem auto;
              background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='17' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 9L1 1v16l15-8z' stroke='%23fff' stroke-width='2' stroke-linejoin='bevel'/%3E%3C/svg%3E");
              background-color: #1C75BC;
            }
            .vjs-icon-placeholder {
              opacity: 0;
            }
          }
        }
        .vjs-poster {
          background-size: cover;
        }
        .vjs-tech {
          border-radius: 24px;
        }
        .vjs-control-bar {
          border-radius: 16px;
          background: rgba(255, 255, 255, 0.05);
          max-width: 50%;
          left: 25%;
          bottom: 20px;
        }
        /*.vjs-big-play-button {
          display: none;
        }*/
      }
    }
    &__header {
      height: 30%;
      background: linear-gradient(180deg, #333333 25.91%, rgba(51, 51, 51, 0) 100%);
      position: absolute;
      z-index: 1;
      width: 100%;
      padding: 2.4rem;
      &__title {
        color: #fff;
        width: 100%;
        font-weight: 400;
        font-size: 3.2rem;
        line-height: 3.6rem;
      }
    }
    &__fullscreen {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 2.4rem;
      width: 3.6rem;
      height: 7.2rem;
      cursor: pointer;
    }
    min-height: 67.2rem;
    &-fullscreen {
      min-height: 95.8rem;
      .video-lectures__player__fullscreen {
        transform: rotate(180deg);
      }
    }
  }
  &__menu {
    margin-bottom: 2.4rem;
    &-title {
      font-size: 2.4rem;
      line-height: 3.2rem;
    }
  }
  &__modules-select {
    .v-select {
      background: #fff!important;
      &:hover {
        background: #fff!important;
      }
      .dropdown-menu li a div {
        white-space: break-spaces;
      }
    }
  }
}

.module__image {
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.preview-video {
  background-size: cover;
}
.section__course h4 {
  font-weight: 700;
  font-size: 3.2rem;
  margin-bottom: 3rem !important;
}
.video-title {
  display: block;
  position: relative;
  .tooltip-video {
    position: absolute;
    background-size: cover;
    display: none;
    width: 200px;
    height: 115px;
    z-index: 5000;
    top: -115px;
    left: 100px;
  }
  &:hover {
    .tooltip-video {
      display: block;
    }
  }
}