
.course-card-mini {
  cursor: pointer;
  &__name {
    height: 46px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
}
