
.element_list {
  &__item {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: space-between;
    background: #ededed;
    border-radius: 10px;
    padding: 10px;
    font-size: 20px;
    line-height: 27px;
    margin-bottom: 22px;
    &.active {
      border: 3px solid #A5A5A5;
    }
  }
}
