
.module {
  margin-top: 20px;
  .mb-16 {
    margin-bottom: 0!important;
  }
  &__menu {
    overflow-y: scroll;
    padding-right: 28px;
    .module-list {
      margin: 0!important;
      background: #fff;
      border-radius: 16px;
      .el-collapse-item__header {
        background: none;
      }
      .el-collapse-item:last-child {
        .el-collapse-item__header {
          border: none;
        }
      }
    }
  }
}
