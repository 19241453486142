.menu {
  display: flex;
  justify-content: space-between;
  width: 100%;
   &__item {
     a {
       text-decoration: none;
       text-transform: uppercase;
       color: #A5A5A5;
       font-weight: 500;
       font-size: 16px;
       line-height: 20px;
       margin-right: 20px;
       &:last-child {
         margin-right: 0;
       }
       svg {
         transition: transform .2s ease-in-out;
       }
       &:hover, &.active {
         color: #1F1F1F;
         svg:not(.non-transform) {
           transform: rotate(45deg);
         }
       }
     }
     &__blue {
       background: rgba(59,130,246,.5);
       border-radius: 40px;
       padding: 5px 20px;
       color: #1F1F1F!important;
     }
   }
  @media screen and (max-width: 1280px) {
    display: none;
  }
}
.mobile-menu {
  @media screen and (min-width: 1281px) {
    display: none;
  }
  display: flex;
  justify-content: flex-end;
  text-align: right;
  width: 100%;
  align-items: center;
  a {
    text-decoration: none;
    color: #000000;

    transition: color 0.3s ease;
  }

  a:hover
  {
    color: tomato;
  }

  #menuToggle
  {
    display: block;
    padding: 15.5px 11.5px;
    border-radius: 50%;
    z-index: 1;
    -webkit-user-select: none;
    background: #ededed;
    user-select: none;
  }

  #menuToggle input
  {
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    padding: 0;
    margin: 0;
    cursor: pointer;

    opacity: 0; /* hide this */
    z-index: 2; /* and place it over the hamburger */

    -webkit-touch-callout: none;
  }

  /*
   * Just a quick hamburger
   */
  #menuToggle span
  {
    display: block;
    width: 20px;
    height: 2px;
    margin-bottom: 3px;
    position: relative;

    background: #000;
    border-radius: 2px;

    z-index: 1;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
    opacity 0.55s ease;
  }

  #menuToggle span:first-child
  {
    transform-origin: 0% 0%;
  }

  #menuToggle span:nth-last-child(2)
  {
    transform-origin: 0% 100%;
  }

  /*
   * Transform all the slices of hamburger
   * into a crossmark.
   */
  #menuToggle input:checked ~ span
  {
    opacity: 1;
    transform: rotate(45deg) translate(-2px, -1px);
    background: #232323;
  }

  /*
   * But let's hide the middle one.
   */
  #menuToggle input:checked ~ span:nth-last-child(3)
  {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
  }

  /*
   * Ohyeah and the last one should go the other direction
   */
  #menuToggle input:checked ~ span:nth-last-child(2)
  {
    opacity: 1;
    transform: rotate(-45deg) translate(0, -1px);
  }

  /*
   * Make this absolute positioned
   * at the top left of the screen
   */
  #menu
  {
    position: absolute;
    width: 250px;
    margin: -100px 0 0 0;
    padding: 50px;
    padding-top: 125px;
    right: -48px;

    background: #ededed;
    border-radius: 0 0 0 24px;
    list-style-type: none;
    -webkit-font-smoothing: antialiased;
    /* to stop flickering of text in safari */

    transform-origin: 0% 0%;
    transform: translate(100%, 0);

    transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  }

  #menu li
  {
    padding: 10px 0;
    font-size: 22px;
  }

  /*
   * And let's fade it in from the left
   */
  #menuToggle input:checked ~ ul
  {
    transform: none;
    opacity: 1;
    visibility: visible;
  }

  @media screen and (max-width: 1024px) {
    #menu {
      transform: none;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
    }
  }
}