.main-page {
  display: flex;
  padding: 10rem 0 5rem;
  width: 100%;
  justify-content: space-between;
  &__title {
    margin: 0 0 180px 140px;
    font-size: 64px;
    line-height: 70px;
  }
  &__links {
    align-self: end;
    margin-right: 24px;
    &-md {
      display: none;
    }
    &__link {
      display: block;
      text-align: right;
      a {
        display: inline-block;
        position: relative;
        .arrow {
          transition: transform 0.2s ease-in;
          position: absolute;
          right: 0;
        }
        .block {
          transition: fill 0.2s ease-in;
        }
        &:hover {
          .block {
            fill: #F7941E;
          }
          .arrow {
            transform: rotate(45deg);
          }
        }
      }
      &:last-child {
        a:hover {
          .block {
            fill: #6EB253;
          }
        }
      }
    }
  }
  &__categories {
    .btn {
      margin: 0 6px 6px 0;
    }
  }
  @media screen and (max-width: 768px) {
    padding-top: 44px;
    &__title {
      margin: 0;
      text-align: center;
      .btn-lg {
        margin-top: 27px!important;
      }
    }
    &__links-md {
      display: block;
      font-weight: 500;
      font-size: 14px;
      line-height: 130%;
      text-align: left;
      &__title {
        font-size: 14px;
        line-height: 130%;
        opacity: 0.5;
      }
      &__text {
        font-size: 24px;
        line-height: 125%;
      }
    }
  }
}