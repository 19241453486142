@for $i from 0 to 151 {
  $side: $i;
  .mr-#{$side} {
    margin-right: #{$side}px!important;
  }
  .ml-#{$side} {
    margin-left: #{$side}px!important;
  }
  .mt-#{$side} {
    margin-top: #{$side}px!important;
  }
  .mb-#{$side} {
    margin-bottom: #{$side}px!important;
  }
  .pr-#{$side} {
    padding-right: #{$side}px!important;
  }
  .pl-#{$side} {
    padding-left: #{$side}px!important;
  }
  .pt-#{$side} {
    padding-top: #{$side}px!important;
  }
  .pb-#{$side} {
    padding-bottom: #{$side}px!important;
  }
  .p-#{$side} {
    padding: #{$side}px!important;
  }
  .m-#{$side} {
    margin: #{$side}px!important;
  }
  .font-#{$side} {
    font-size: #{$side}px!important;
    line-height: 1!important;
  }
  .line-#{$side} {
    line-height: #{$side}px!important;
  }
}

@for $i from 0 to 151 {
  $side: $i;
  @media screen and (max-width: 768px) {
    .mb-#{$side}-md {
      margin-bottom: #{$side}px!important;
    }
    .mt-#{$side}-md {
      margin-top: #{$side}px!important;
    }
    .pt-#{$side}-md {
      padding-top: #{$side}px!important;
    }
    .pb-#{$side}-md {
      padding-bottom: #{$side}px!important;
    }
    .p-#{$side}-md {
      padding: #{$side}px!important;
    }
    .m-#{$side}-md {
      margin: #{$side}px!important;
    }
    .font-#{$side}-md {
      font-size: #{$side}px!important;
      line-height: 1!important;
    }
    .line-#{$side}-md {
      line-height: #{$side}px!important;
    }
  }
}

.d-flex {
  display: flex!important;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-direction-col {
  flex-direction: column;
}
.align-center {
  align-items: center;
}
.align-start {
  align-items: start;
}
.justify-space-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.justify-space-around {
  justify-content: space-around;
}
.justify-start {
  justify-content: flex-start;
}
.justify-end {
  justify-content: flex-end;
}

.text-center {
  text-align: center;
  &-md {
    @media screen and (max-width: 768px) {
      text-align: center;
    }
  }
}
a {
  color: #1F1F1F;
  text-decoration: none;
}
.d-block {
  display: block!important;
}