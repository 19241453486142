@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:wght@100;400&display=swap');

body, html {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
}

.wrapper {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 16px;
  }
}

.fill {
  display: block;
  border-radius: 24px;
  padding: 40px 40px 60px 40px;
  @media screen and (max-width: 1024px) {
    padding: 32px 20px;
  }
  &-sm {
    border-radius: 24px;
    padding: 30px 26px 48px 30px;
  }
  &-grey {
    background: #ededed;
  }
  &-white {
    background: #fff;
  }
  &-grey-outline {
    border: 4px solid #ededed;
  }
  &-blue {
    background: rgba(59,130,246,.5);
  }
  &-green {
    background: #6EB253;
  }
  &-danger {
    background: #E55151;
  }
  &-orange {
    background: #F7941E;
  }
  &-purple {
    background: rgba(59,130,246,.5);
  }
}


input {
  border: none;
  outline: none;
  &::placeholder {
    color: #A5A5A5;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: #A5A5A5;
  }
  &::-ms-input-placeholder {
    color: #A5A5A5;
  }
}
.search-input {
  position: relative;
  svg {
    position: absolute;
    z-index: 1;
    top: 12px;
    left: 12px;
  }
  input[type="search"] {
    background: #FFFFFF;
    border-radius: 32px;
    padding: 16px 24px 16px 48px;
    border: none;
    font-size: 16px;
    line-height: 135%;
    display: inline-block;
    max-width: 526px;
    width: 100%;
    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      appearance: none;
      height: 10px;
      width: 10px;
      background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjEyMy4wNXB4IiBoZWlnaHQ9IjEyMy4wNXB4IiB2aWV3Qm94PSIwIDAgMTIzLjA1IDEyMy4wNSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTIzLjA1IDEyMy4wNTsiDQoJIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTEyMS4zMjUsMTAuOTI1bC04LjUtOC4zOTljLTIuMy0yLjMtNi4xLTIuMy04LjUsMGwtNDIuNCw0Mi4zOTlMMTguNzI2LDEuNzI2Yy0yLjMwMS0yLjMwMS02LjEwMS0yLjMwMS04LjUsMGwtOC41LDguNQ0KCQljLTIuMzAxLDIuMy0yLjMwMSw2LjEsMCw4LjVsNDMuMSw0My4xbC00Mi4zLDQyLjVjLTIuMywyLjMtMi4zLDYuMSwwLDguNWw4LjUsOC41YzIuMywyLjMsNi4xLDIuMyw4LjUsMGw0Mi4zOTktNDIuNGw0Mi40LDQyLjQNCgkJYzIuMywyLjMsNi4xLDIuMyw4LjUsMGw4LjUtOC41YzIuMy0yLjMsMi4zLTYuMSwwLTguNWwtNDIuNS00Mi40bDQyLjQtNDIuMzk5QzEyMy42MjUsMTcuMTI1LDEyMy42MjUsMTMuMzI1LDEyMS4zMjUsMTAuOTI1eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=);
      background-size: 10px 10px;
      cursor: pointer;
    }
  }
}
.text-rothek {
  font-family: 'Rothek', sans-serif;
  margin: 0;
}
h1.text-rothek {
  font-weight: 700;
  font-size: 64px;
  line-height: 70.4px;
  font-style: normal;
  @media screen and (max-width: 768px) {
    font-size: 40px!important;
    line-height: 110%!important;
  }
}
h2.text-rothek {
  font-weight: 700;
  font-size: 48px;
  line-height: 52.8px;
  @media screen and (max-width: 768px) {
    font-size: 24px!important;
    line-height: 125%!important;
  }
}
h3.text-rothek {
  font-weight: 700;
  font-size: 36px;
  line-height: 1;
  @media screen and (max-width: 768px) {
    font-size: 24px!important;
    line-height: 125%!important;
  }
}

.color {
  &-blue {
    color: rgba(59,130,246,.5);
  }
  &-orange {
    color: #F7941E;
  }
  &-purple {
    color: rgba(59,130,246,.5);
  }
  &-red {
    color: #E55151;
  }
  &-white {
    color: #fff;
  }
  &-dark {
    color: #1f1f1f;
  }
  &-dark-mute {
    color: rgba(31, 31, 31, 0.50);
  }
  &-grey {
    color: #A5A5A5;
  }
  &-navy {
    color: #4C7EDF;
  }
  &-green {
    color: #6EB253;
  }
}
.bg {
  &-blue {
    background: rgba(59,130,246,.5);
    color: #fff!important;
  }
  &-orange {
    background: #F7941E;
  }
  &-purple {
    background: rgba(59,130,246,.5);
  }
  &-red {
    background: #E55151;
  }
  &-yellow {
    background: #F7C71E;
  }
  &-dark {
    background: #1F1F1F;
  }
  &-white {
    background: #fff;
  }
  &-navy {
    background: #4C7EDF;
  }
  &-green {
    background: #6EB253;
  }
  &-grey {
    background: #ededed;
  }
}
.badge {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding: 5px 8px;
  border-radius: 4px;
  display: inline-block;
}

.md-hidden {
  @media screen and (max-width: 768px) {
    display: none;
  }
  @media screen and (min-width: 768px) {
    display: block;
  }
}

.md-visible {
  display:block;
  @media screen and (min-width: 768px) {
    display:none;
  }
}

.lg-hidden {
  @media screen and (max-width: 1024px) {
    display: none;
  }
  @media screen and (min-width: 1024px) {
    display: block;
  }
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  to {
    background-position: 0 50%;
  }
}

@mixin skeletonBg {
  background: linear-gradient(
                  90deg,
                  rgba(0, 0, 0, .06) 25%,
                  rgba(0, 0, 0, .1) 37%,
                  rgba(0, 0, 0, .06) 63%
  );
  background-size: 400% 100%;

  animation: skeleton-loading 1.4s ease infinite;
}

.skeleton {
  @include skeletonBg;
}

.logo {
  img {
    width: 200px;
    max-height: 75px;
  }
}

/*
.el-col {
  * {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  *:before,
  *:after {
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
}*/
