
.tutor-avatar {
  &__img {
    width: 106px;
    height: 130px;
    background-size: cover;
    background-position: center center;
    border-radius: 16px;
  }
}
