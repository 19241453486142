
.profile {
  position: relative;
  &__edit {
   position: absolute;
   right: 40px;
   top: 40px;
   @media screen and (max-width: 768px) {
     right: 20px;
     top: 20px;
   }
  }
  &__stats {
    &-item {
      width: 60px;
      height: 76px;
      padding: 14px 10px;
      border-radius: 10px;
      margin-bottom: 12px;
      overflow-x: hidden;
      text-overflow: ellipsis;
      font-weight: 400;
      font-size: 12px;
      box-sizing: content-box;
    }
  }
}
