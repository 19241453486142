.el-dialog {

  border-radius: 0 0 24px 24px;
  background: #ededed;

  &__wrapper {
    > div {
      width: 453px;
      @media screen and (max-width: 768px) {
        max-width: 100%;
      }
    }
  }

  &__header {
    width: calc(100% - 109px);
    position: absolute;
    top: -49px;
    background: #ededed;
    border-radius: 24px 0 0 0;
    padding-top: 30px;
    padding-left: 40px;
  }
  &__title {
    font-size: 24px;
    font-family: 'Rothek', sans-serif;
  }
  &__close {
    position: absolute;
    width: 49px;
    height: 49px;
    right: 0;
    top: -49px;
    cursor: pointer;
    background-image: url("data:image/svg+xml,%3Csvg width='49' height='49' viewBox='0 0 49 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='49' height='49' fill='none'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M9 11.2821C9 8.22727 9 6.69987 8.58829 5.46666C7.79173 3.08066 5.91934 1.20827 3.53334 0.411708C2.30013 0 0.772738 0 -2.28204 0H-365.6C-379.041 0 -385.762 0 -390.896 2.61584C-395.412 4.9168 -399.083 8.58834 -401.384 13.1042C-404 18.2381 -404 24.9587 -404 38.4V561.6C-404 575.041 -404 581.762 -401.384 586.896C-399.083 591.412 -395.412 595.083 -390.896 597.384C-385.762 600 -379.041 600 -365.6 600H10.6C24.0413 600 30.7619 600 35.8958 597.384C40.4117 595.083 44.0832 591.412 46.3842 586.896C49 581.762 49 575.041 49 561.6V50.9158C49 47.7239 49 46.1279 48.5511 44.8449C47.747 42.547 45.9402 40.7402 43.6422 39.9361C42.3593 39.4872 40.7281 39.4872 37.4658 39.4872V39.4872C29.6551 39.4872 25.7497 39.4872 22.6666 38.4579C16.7017 36.4665 12.0207 31.7855 10.0293 25.8205C9 22.7375 9 18.919 9 11.2821V11.2821Z' fill='%23F5F5F5'/%3E%3Cpath d='M15 17C15 7.61116 22.6112 0 32 0V0C41.3888 0 49 7.61116 49 17V17C49 26.3888 41.3888 34 32 34V34C22.6112 34 15 26.3888 15 17V17Z' fill='%23F5F5F5'/%3E%3Cpath d='M28 22L37.4281 12.5719' stroke='%23131313' stroke-width='1.5'/%3E%3Cpath d='M37 22L27.5719 12.5719' stroke='%23131313' stroke-width='1.5'/%3E%3C/svg%3E");
  }
  &__subtitle {
    color: #A5A5A5;
    margin: 0 0 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 130%;
  }
  &__body {
    padding: 16px 0 40px 40px;
    width: 310px;
    .form-separator {
      width: 130px;
      border: 1px solid #D6D6D6;
    }
    .social-enter {
      &__button {
        border-radius: 10px;
        border: 1px solid #D6D6D6;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 16px;
        padding: 8px 24px;
      }
      &__icon {
        display: flex;
        align-items: center;
        img {
          width: 100%;
        }
        height: 15px;
        width: 15px;
        &-vk {
          margin-right: 8px;
        }
        &-ya {
          height: 17px;
          width: 17px;
        }
      }
    }
    .el-form {
      &-item {
        margin-bottom: 16px;
        .el-input {
          input {
            border-radius: 10px;
            background: #fff;
            border: none;
          }
        }
      }
    }
    .link-forgot {
      color: #006EEF;
    }
    @media screen and (max-width: 768px) {
      max-width: 100%;
      padding: 16px;
    }
  }
}