.header {
  display: flex;
  padding: 24px 0;
  flex-direction: row;
  .router-link-active {
    margin-right: 45px;
    max-width: 60%;
  }
  position: relative;
  @media screen and (max-width: 380px) {
    padding-right: 24px;
  }
}