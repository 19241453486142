
.institutes {
  &__buttons {
    padding: 40px 0;
    text-align: center;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
  .logo-block {
    margin-top: 32px;
    padding: 24px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
}
